
import { Options, Vue } from 'vue-class-component'
import PlanDetail from '@/components/PlanDetail.vue'
import service from '@/utils/request'
import dayjs from 'dayjs'
import { Plan } from '@/models/plan'
import NaviBar from '@/components/NaviBar.vue'
import { PlayList, Practice } from '@/models/practice'
import PracticeCard from '@/components/PracticeCard.vue'
import { Toast } from 'vant'

@Options({
  components: {
    PlanDetail,
    NaviBar,
    PracticeCard
  }
})
export default class PlanList extends Vue {
  plans: Plan[] = []
  activeName = 'recent'
  practices: PlayList[] = []
  collapseName = 1

  planPractices: Practice[][] = []
  currentIndex = 0

  mounted () {
    this.loadData()
    this.loadRecentPractice()
  }

  loadData () {
    service.get('/mindup/mini/my-data/plan-journey/list', {}).then(res => {
      this.plans = res.data
      this.plans.forEach(item => {
        this.planPractices.push([])
      })
      if (this.plans.length > 0) {
        this.loadPlanPractices(this.currentIndex)
      }
    })
  }

  getDate (date: string) {
    return dayjs(date).format('M月D日')
  }

  tapPlan (index: number) {
    this.$router.push({
      name: 'PlanPracticeList',
      params: { planId: this.plans[index].id }
    })
  }

  loadRecentPractice () {
    service.get('/mindup/mini/my-data/practice-played-logs/list', {
      params: {
        sort: '-updateTime'
      }
    }).then(res => {
      this.practices = res.data.content
    })
  }

  loadPlanPractices (index: number) {
    const planId = this.plans[index].id
    service.get('/mindup/mini/plans/' + planId + '/practices', {}).then(res => {
      console.log(res.data)
      this.planPractices.splice(index, 1, res.data)
    })
  }

  tapPractice (index: number, subIndex: number) {
    service.post('/mindup/mini/practice/' + this.planPractices[index][subIndex].id + '/daily-practice/add-or-cancel', {}).then(res => {
      if (res.data.isDailyPractice) {
        Toast.success('添加成功')
      } else {
        Toast.success('取消成功')
      }
      this.planPractices[index][subIndex].isDailyPractice = res.data.isDailyPractice
    })
  }

  tapRecentPractice (index: any) {
    service.post('/mindup/mini/practice/' + this.practices[index].practiceId + '/daily-practice/add-or-cancel', {}).then(res => {
      if (res.data.isDailyPractice) {
        Toast.success('添加成功')
      } else {
        Toast.success('取消成功')
      }
      this.practices[index].isDailyPractice = res.data.isDailyPractice
    })
  }
}
